import "./Events.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";

const Meetups = () => {
  return (
    <>
      <div>
      <Swiper
          watchSlidesProgress={true}
          // slidesPerView={3}
          slidesPerView={3}
          spaceBetween={11}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
           
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card">React Meetup 1st edition</p>
              <p className="thinktech-description">
                React Meetup was an event organized for all the developers and
                people who are passionate about technology. It had three main
                speakers.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">24th Jun 2021</p>
              </div>
              <a
                href="/react-meetup-1st-edition"
                className="event-informations"
              >
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card">React Meetup 2nd edition</p>
              <p className="thinktech-description">
                We are happy to welcome you to our second React Albania Meetup
                2021 event for all the developers and people who are passionate
                about technology.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">3rd Nov 2021</p>
              </div>
              <a
                href="/react-meetup-2nd-edition"
                className="event-informations"
              >
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card-uxui">UX/UI Meetup</p>
              <p className="thinktech-description-uxui">
                We were currently organizing the fifth edition of the Think Tech
                conference, but for the first time in Pristina, on June 16,
                2022, at Klan Arena...
                
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">26th Aug 2021</p>
              </div>
              <a href="/ux-ui-meetup" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default Meetups;
