import "./Editions.css";
import { useState , useEffect} from "react";
import Slider from "react-slick";
import reactE1 from "../assets/img/ReactMeetup1/reactE1.jpg";
import reactE2 from "../assets/img/ReactMeetup1/reactE2.jpg";
import reactE4 from "../assets/img/ReactMeetup1/reactE4.jpg";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [reactE1, reactE2, reactE4];

const ReactMeetUpFirstEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: false,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = model ? 'hidden' : 'auto';
  }, [model])

  return (
    <>
      <Header />
      <div className="homepage homepage-r1">
        <h2 className="title">React 1st edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Pool Tower</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">24th Jun 2021</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            React Meetup was an event organized for all the developers and
            people who are passionate about technology. It had three main
            speakers.
            <br />
            <br />
            Danilo Kasemi, a well-versed Software Developer and Java Trainer,
            with Master’s Degree (MSc) in software engineering. He owns
            experience on different programming languages like Java, C# and
            JavaScript, and also frameworks like AEM, React library etc. He is
            very focused on Performance-Optimizing Design Patterns and Machine
            Learning. Danilo moderated the event and also shared some details on
            Redux, Architecture and performance details of React and other info.
            <br />
            <br />
            Anisa Tocila, an experienced Software Developer focused on
            JavaScript technologies. She has a long-standing iteration with
            tools and projects with React, but also other SW development
            technologies/ languages like Databases, PHP, Node etc. Her primary
            career goal is to grow her expertise, to be updated in new
            technologies and use them as tools to deliver better products to
            enhance the market. Anisa shared to React fans the plan of the
            release 18 of React and what are its main benefits, features etc.
            <br />
            <br />
            Soni Lamkaj, with a full flavored background in technology, from a
            fellow student and researcher in Philadelphia University to Mobile
            and BE/FE Developer, including here also JavaScript, his passion. He
            is now focused on MERN Stack with particular attention to React.js
            and related frameworks. His career goal is to keep growing towards
            excellence and build beautiful things. Soni shared some details
            about Material UI use in React with the community.
          </p>
          <div className={model ? "model open" : "model"}>
          <div className="img-container">
            <img src={tempimgSrc} alt="react1meetup" />
            <span className="close-icon close-icon-r1" onClick={() => setModel(false)}>
              x
            </span>
          </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className= "slide"
                onClick={() => getImg(img)}
              >
                <img src={img} alt="react1meetup" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ReactMeetUpFirstEdition;
