import { useState, useEffect } from "react";
import "./Editions.css";
import Slider from "react-slick";
import DSC3988 from "../assets/img/ThinkTech2/DSC3988.JPG";
import DSC4103 from "../assets/img/ThinkTech2/DSC4103.JPG";
import DSC4170 from "../assets/img/ThinkTech2/DSC4170.JPG";
import DSC4240 from "../assets/img/ThinkTech2/DSC4240.JPG";
import DSC4296 from "../assets/img/ThinkTech2/DSC4296.JPG";
import DSC4312 from "../assets/img/ThinkTech2/DSC4312.JPG";

import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [DSC3988, DSC4103, DSC4170, DSC4240, DSC4296, DSC4312];

const ThinkTechSecondEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  return (
    <>
      <Header />
      <div className="homepage homepage-th2">
        <h2 className="title">ThinkTech 2nd edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Thinktech Livestream</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">23rd Dec 2020</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            Landmark is always coherent, so it presented a completely innovative
            technological event, which took place in the form of a competition,
            where talented young people and passionate about technology, brought
            fantastic ideas and visionary products, realized in a relatively
            short time.
            <br />
            <br />
            “Hackathon” came as a continuation of the first technological
            conference in Albania, “Think Tech”, which took place in February
            2020 and was very successful, as it focused on the development of
            young people, their careers in Albania and the opportunities that
            partners provide them. Participants became more familiar with
            partner initiatives, success stories, as well as training and
            professional advice that help professional growth and contribute
            best to the local market.
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech2edition" />
              <span
                className="close-icon close-icon-th2"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech2edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechSecondEdition;
