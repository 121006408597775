import { useState, useEffect } from "react";
import "./Editions.css";
import Slider from "react-slick";

import thinkTech from "../assets/img/ThinkTech3/thinkTech.jpg";
import thinktech3rd1 from "../assets/img/ThinkTech3/thinktech3rd1.jpg";
import thinktech3rd2 from "../assets/img/ThinkTech3/thinktech3rd2.jpg";
import thinktech3rd3 from "../assets/img/ThinkTech3/thinktech3rd3.jpg";
import thinktech3rd4 from "../assets/img/ThinkTech3/thinktech3rd4.jpg";
import thinktech3rd5 from "../assets/img/ThinkTech3/thinktech3rd5.jpg";
import thinktech3rd6 from "../assets/img/ThinkTech3/thinktech3rd6.jpg";
import thinktech3rd7 from "../assets/img/ThinkTech3/thinktech3rd7.jpg";
import thinktech3rd8 from "../assets/img/ThinkTech3/thinktech3rd8.jpg";
import thinktech3rd9 from "../assets/img/ThinkTech3/thinktech3rd9.jpg";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [
  thinkTech,
  thinktech3rd1,
  thinktech3rd2,
  thinktech3rd3,
  thinktech3rd4,
  thinktech3rd5,
  thinktech3rd6,
  thinktech3rd7,
  thinktech3rd8,
  thinktech3rd9,
];
const ThinkTechThirdEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  return (
    <>
      <Header />
      <div className="homepage homepage-th3">
        <h2 className="title">ThinkTech 3rd edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">
              Cultural Center of Orthodox Church
            </p>
          </div>
          <div className="date-icon">
            <p className="date-editions">30th Sep 2021</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            On September 30, 2021, was held for the second time in Albania, the
            conference of technological innovations, christened Think Tech.
            <br />
            <br />
            Always trying to bring our innovative ideas, this time we presented
            the conference that brought together the experiences of local
            companies and our partners from the most developed European
            countries, including the Netherlands, Italy, England, etc.
            <br />
            <br />
            The main goal of Think Tech is to unveil the many opportunities that
            our country offers, raising awareness of young people to contribute
            to our country, to make the change we need so much.
            <br />
            <br />
            Why Think Tech?
            <br />
            <br />
            Think Tech is not an ordinary conference, on the contrary, it is a
            unique event which focuses on youth development, partner
            initiatives, as well as training and professional advice in the
            local market.
            <br />
            <br />
            Presented by 10 lecturers, from the fields of technology and
            entrepreneurship, this conference originally intertwines the
            visions, success stories and the experiences in the fields of
            digital development and communication. This meeting also serves as a
            resource where you can be informed about the professional
            opportunities offered by Landmark Communications and Technologies
            and Móveo srl, as well as by our national and international
            partners.
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech3edition" />
              <span
                className="close-icon close-icon-th3"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech3edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechThirdEdition;
