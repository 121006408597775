import React from "react";
import "./Partners.css";
import Moveo from "../../assets/img/Partners/Moveo.png";
import One from "../../assets/img/Partners/One.png";
import Sisal from "../../assets/img/Partners/Sisal.png";
import Blafin from "../../assets/img/Partners/Balfin.png";
import InnovationPartners from "../../assets/img/Partners/InnovationPartners.png";
import MyCicero from "../../assets/img/Partners/MyCicero.png";

const Partners = () => {
  return (
    <>
      <div className="partners" id="partners">
        <h3 className="partners-title">Partners</h3>
        <div className="companies">
          <div className="image-container">
            <img src={Moveo} className="moveo" alt="moveo" />
          </div>
          <div className="image-container">
            <img src={One} className="one" alt="one" />
          </div>
          <div className="image-container">
            <img src={Sisal} className="sisal" alt="sisal" />
          </div>
          <div className="image-container">
            <img src={Blafin} className="balfin" alt="balfin" />
          </div>
          <div className="image-container">
            <img
              src={InnovationPartners}
              className="innovationpartners"
              alt="innovationpartners"
            />
          </div>
          <div className="image-container">
            <img src={MyCicero} className="mycicero" alt="mycicero" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Partners;
