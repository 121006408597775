import "./HeaderSlider.css";
import Slider from "react-slick";
import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeaderSlider = (props) => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          speed: 8000,
          autoplaySpeed: 4000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="card">
      <Slider {...settings}>
        <div>
          <a href="/thinktech-1st-edition" className="text">
            ThinkTech 1st Edition
          </a>
        </div>
        <div>
          <a href="/thinktech-2nd-edition" className="text">
            ThinkTech 2st Edition
          </a>
        </div>
        <div>
          <a href="/thinktech-3rd-edition" className="text">
            ThinkTech 3rd Edition
          </a>
        </div>
        <div>
          <a href="/thinktech-4th-edition" className="text">
            ThinkTech 4th Edition
          </a>
        </div>
        <div>
          <a href="/thinktech-5th-edition" className="text">
            ThinkTech 5th Edition
          </a>
        </div>
        <div>
          <a href="/thinktech-6th-edition" className="text">
            ThinkTech 6th Edition
          </a>
        </div>
        <div>
          <a href="/hackathon-1st-edition" className="text">
            Hackathon 1st Edition
          </a>
        </div>
        <div>
          <a href="/hackathon-2nd-edition" className="text">
            Hackathon 2nd Edition
          </a>
        </div>
        <div>
          <a href="/react-meetup-1st-edition" className="text">
            React Meetup 1st Edition
          </a>
        </div>
        <div>
          <a href="/react-meetup-2nd-edition" className="text">
            React Meetup 2nd Edition
          </a>
        </div>
        <div>
          <a href="/ux-ui-meetup" className="text">
            UX/UI Meetup
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default HeaderSlider;
