import { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Editions.css";
import FooterMain from "../layouts/Footer/FooterMain";
import hackathon1 from "../assets/img/Hackathon1/hackathon1.jpg";
import hackathon2 from "../assets/img/Hackathon1/hackathon2.jpg";
import hackathon3 from "../assets/img/Hackathon1/hackathon3.jpg";
import hackathon4 from "../assets/img/Hackathon1/hackathon4.jpg";
import hackathon5 from "../assets/img/Hackathon1/hackathon5.jpg";
import hackathon6 from "../assets/img/Hackathon1/hackathon6.jpg";
import hackathon7 from "../assets/img/Hackathon1/hackathon7.jpg";
import hackathon8 from "../assets/img/Hackathon1/hackathon8.jpg";
import hackathon9 from "../assets/img/Hackathon1/hackathon9.jpg";
import Header from "../layouts/Header/Header";

const images = [
  hackathon1,
  hackathon2,
  hackathon3,
  hackathon4,
  hackathon5,
  hackathon6,
  hackathon7,
  hackathon8,
  hackathon9,
];

const HackathonFirstEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = model ? 'hidden' : 'auto';
  }, [model])

  return (
    <>
      <Header />
      <div className="homepage homepage-h1">
        <h2 className="title">Hackathon 1st edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Mak Albania</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">23rd Dec 2020</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            “Hackathon”, the technological event organized by Landmark for the
            first time in Albania. Interesting terms that have conquered the
            world of technology, seem to be back in trend within a very short
            time. The year 2020 served us the new “hackathon” trend, which was
            immediately embraced by all.
            <br />
            <br />
            Landmark is always coherent, so it presented a completely innovative
            technological event, which took place in the form of a competition,
            where talented young people and passionate about technology, brought
            fantastic ideas and visionary products, realized in a relatively
            short time.
            <br />
            <br />
            “Hackathon” came as a continuation of the first technological
            conference in Albania, “Think Tech”, which took place in February
            2020 and was very successful, as it focused on the development of
            young people, their careers in Albania and the opportunities that
            partners provide them. Participants became more familiar with
            partner initiatives, success stories, as well as training and
            professional advice that help professional growth and contribute
            best to the local market.
            <br />
            <br />
            This time, “Think Tech Hackathon” brought another innovation, a very
            interesting topic such as “Internet of Things” (IoT). This topic
            consists of the fact that all devices that exist in the world and
            are in our function, can be connected to the Internet.
            <br />
            <br />
            Full 7 teams composed of experts in various technological fields,
            challenged each other, implementing their brilliant ideas, under the
            pressure of time. As in any strong competition, only one managed to
            triumph and be awarded the first prize of 1,000 euros.
            <br />
            <br />
            At Landmark it has always been and will continue to be a tradition
            that every talented young person is valued as a very important asset
            and is constantly motivated, being rewarded for his contribution.
            <br />
            <br />
            This event had very important partners such as: “Sisal Albania Hub”,
            “One Telecommunications”, “Moveo Albania Technology”, “Balfin Group”
            and “Landmark”, as the creator and creator of Hackathon. Thanks to
            their cooperation and dedication, an extraordinary success was
            achieved once again.
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
            <img src={tempimgSrc} alt="hackathon1edition" />
            <span className="close-icon close-icon-h1" onClick={() => setModel(false)}>
              x
            </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className="slide"
                onClick={() => getImg(img)}
              >
                <img src={img} alt="hackathon1edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default HackathonFirstEdition;
