import React from "react";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <>
      <div class="mainbox">
        <div class="err">4</div>
        <i class="far fa-question-circle fa-spin"></i>
        <div class="err2">4</div>
        <div class="msg">
          Maybe this page moved?
          <p>
            Let's go{" "}
            <a className="home-redirect" href="/">
              home!
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
