import "./Events.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";

const ThinkTechEvents = () => {
  return (
    <>
      <div>
        <Swiper
          watchSlidesProgress={true}
          // slidesPerView={3}
          slidesPerView={3}
          spaceBetween={11}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 1st edition</p>
              <p className="thinktech-description">
                For the first time, Landmark Communications and Technologies
                introduced a conference which focuses entirely on technology as
                the field of the future.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">ArTurbina</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">5th Feb 2020</p>
              </div>
              <a href="/thinktech-1st-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 2nd edition</p>
              <p className="thinktech-description">
              On December 23rd 2021, was held for the second time in Albania,
                the conference of technological innovations, christened Think
                Tech.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Thinktech Livestream</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">23rd Dec 2020</p>
              </div>
              <a href="/thinktech-2nd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 3rd edition</p>
              <p className="thinktech-description">
                We invite you to participate on September 30th and get inspired by
                some of the most successful professionals in Albania who will
                share with us what drives them...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                “Jordan Misja” Artistic Lyceum
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">30th Sep 2021</p>
              </div>
              <a href="/thinktech-3rd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 4th edition</p>
              <p className="thinktech-description">
                We invite you to participate on March 31st and get inspired by
                some of the most successful professionals in Albania who will
                share with us what drives them towards working in Albania...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                  “Tonin Harapi” Concert Hall
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">31st March 2022</p>
              </div>
              <a href="/thinktech-5th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 5th edition</p>
              <p className="thinktech-description">
                We were currently organizing the fifth edition of the Think Tech
                conference, but for the first time in Pristina, on June 16,
                2022, at Klan Arena... Don’t miss this great opportunity!
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Klan Arena, Prishtinë</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">16th Jun 2022</p>
              </div>
              <a href="/thinktech-5th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>
          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 6th edition</p>
              <p className="thinktech-description">
              Welcome to the 6th edition of ThinkTech, powered by Móveo, with the
              patronage of the Municipality of Cosenza and the University of
              Calabria....
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Castello Svevo Cosenza, Italy</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">27th Oct 2022</p>
              </div>
              <a href="/thinktech-6th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default ThinkTechEvents;
