import "./Editions.css";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import foto4 from "../assets/img/ThinkTech6/foto4.jpg";
import foto5 from "../assets/img/ThinkTech6/foto5.jpg";
import foto6 from "../assets/img/ThinkTech6/foto6.jpg";
import foto9 from "../assets/img/ThinkTech6/foto9.jpg";
import foto11 from "../assets/img/ThinkTech6/foto11.jpg";
import foto17 from "../assets/img/ThinkTech6/foto17.jpg";
import foto20 from "../assets/img/ThinkTech6/foto20.jpg";
import foto23 from "../assets/img/ThinkTech6/foto23.jpg";
import IMG4830 from "../assets/img/ThinkTech6/IMG4830.jpg"
import IMG4857 from "../assets/img/ThinkTech6/IMG4857.jpg"
import IMG7895 from "../assets/img/ThinkTech6/IMG7895.jpg"
import IMG7987 from "../assets/img/ThinkTech6/IMG7987.jpg"
import IMG8791 from "../assets/img/ThinkTech6/IMG8791.JPG"
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [foto11, foto17, foto20, foto23, foto4, foto5, foto6, foto9, IMG4830, IMG4857, IMG7895, IMG7987, IMG8791];

const ThinkTechSixthEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  return (
    <>
      <Header />
      <div className="homepage homepage-th5">
        <h2 className="title">ThinkTech 6th edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Castello Svevo Cosenza, Italy</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">27th October 2022</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            Welcome to the 6th edition of ThinkTech, powered by Móveo, with the
            patronage of the Municipality of Cosenza and the University of
            Calabria.
            <br />
            <br />A must-attend event focused on professional development,
            industry advances and also on building bridges better and faster,
            between Italy and the Balkans. Join us for a powerful series of
            leadership sessions from distinguished speakers that will provide
            inspiration and amazing networking experiences.
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech5edition" />
              <span
                className="close-icon close-icon-th5"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech5edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechSixthEdition;
