import CountdownTimer from "../components/CountdownTimer/CountdownTimer";
import HeaderSlider from "../layouts/Header/HeaderSlider";
import "./Home.css";
import React, { useState, useEffect } from "react";
import Partners from "../components/Partners/Partners";
import Events from "../components/Events/Events";
import Header from "../layouts/Header/Header";
import RegisterPopUp from "../components/Register/Register-PopUp";
import FooterMain from "../layouts/Footer/FooterMain";
import ThinktechVideo from "../assets/img/ThinktechVideo.mp4"

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleRegister = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = isOpen ? "hidden" : "auto";
  }, [isOpen]);

  return (
    <>
      <Header />
      <div id="home">
        <div className="home container">
          <section className="section-home-page">
            {/* <h1 className="saveTheDate">Save the date</h1>
            <CountdownTimer countdownTimestampMs={1666877460000} />
            <div className="counter-details"><p>Days : Hours : Minutes</p></div>
            <div className="youtube-icon">
              <a
                href="https://www.youtube.com/watch?v=LqVRnYTe0ck"
                target="blank"
                className="youtube"
              >
                WATCH NOW ON YOUTUBE
              </a>
            </div>
            <a href="/thinktech-6th-edition" className="editions">ThinkTech 6th edition</a>
         
            <div className="parent">
              <div className="locationicon">
                <div className="location">Location: Cosenza</div>
              </div>
              <div className="dateicon">
                <div className="date">When: 27th October 2022</div>
              </div>
            </div> */}
            {/* <button className="register" onClick={toggleRegister}>
              Register
            </button> */}
            {/* <div>
              {isOpen && <RegisterPopUp handleClose={toggleRegister} />}
            </div> */}
            <video autoPlay loop muted className="video">
              <source src={ThinktechVideo} type='video/mp4'/>
            </video>
          </section>
        </div>
        <HeaderSlider />
      </div>
      <div className="about-section">
        <h3 className="about">ABOUT</h3>
        <h1 className="about-title">
          Think Tech conference which focuses  completely on technology as the
          field of the future
        </h1>
        <br />
        <p className="about-paragraph">
          For the first time, Landmark Communications and Technologies
          introduced a conference which focuses entirely on technology as the
          field of the future. We have tried to bring an activity different from
          events or conferences that address the same topic, serving new areas
          and opportunities in the field of technology. Whether this will be
          innovative, attractive and useful is up to the public and the
          participants to decide.
        </p>
        <a href="/about" className="about-informations">
          <p className="more-informations">
            More information about the conference and the organizer
          </p>
        </a>
      </div>
      <Events />
      <Partners />
      <FooterMain />
    </>
  );
};

export default Home;
