import "./Editions.css";
import Slider from "react-slick";
import { useState, useEffect } from "react";

import ALB7993 from "../assets/img/ThinkTech5/ALB7993.jpg";
import ALB8100 from "../assets/img/ThinkTech5/ALB8100.jpg";
import ALB8123 from "../assets/img/ThinkTech5/ALB8123.jpg";
import ALB8283 from "../assets/img/ThinkTech5/ALB8283.jpg";
import ALB8625 from "../assets/img/ThinkTech5/ALB8625.jpg";
import DSC3048 from "../assets/img/ThinkTech5/DSC3048.jpg";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [ALB7993, ALB8100, ALB8123, ALB8283, ALB8625, DSC3048];

const ThinkTechFifthEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  return (
    <>
      <Header />
      <div className="homepage homepage-th5">
        <h2 className="title">ThinkTech 5th edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Klan Arena, Prishtinë</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">16th June 2022</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            We were currently organizing the fifth edition of the Think Tech
            conference, but for the first time in Pristina, on June 16, 2022, at
            Klan Arena. This edition’s topic was “Building bridges” between
            Kosovo and Albania, in all possible dimensions.
            <br />
            <br />
            We invited you to participate on June 16th and get inspired by some
            of the most successful professionals in Kosovo and Albania who
            shared with us what drives them toward working in these countries.
            <br />
            <br />
            Don’t miss the great opportunity to create your network!
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech5edition" />
              <span
                className="close-icon close-icon-th5"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech5edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechFifthEdition;
