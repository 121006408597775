import "./Events.css";
import "swiper/css";
import { useState } from "react";
import ThinkTechEvents from "./ThinkTechEvents";
import HackathonEvents from "./HackathonEvents";
import Meetups from "./Meetups";
import AllEvents from "./AllEvents";

const Events = () => {
  const [isThinkTechVisible, setIsThinktechVisible] = useState(false);
  const [isHackathonVisible, setIsHackathonVisible] = useState(false);
  const [isMeetupsVisible, setIsMeetupsVisible] = useState(false);
  const [active, setActive] = useState("");

  const handleThinktech = (e) => {
    setIsThinktechVisible((isVisible) => !isVisible);
    setIsHackathonVisible(false);
    setIsMeetupsVisible(false);
    setActive(e.target.id);
  };

  const handleHackathon = (e) => {
    setIsHackathonVisible((isVisible) => !isVisible);
    setIsThinktechVisible(false);
    setIsMeetupsVisible(false);
    setActive(e.target.id);
  };

  const handleMeetups = (e) => {
    setIsMeetupsVisible((isVisible) => !isVisible);
    setIsHackathonVisible(false);
    setIsThinktechVisible(false);
    setActive(e.target.id);
  };
  return (
    <>
      <div className="events" id="events">
        <ul className="event-table">
          <h3 className="events-title">Events</h3>
          <li className="event-list">
            <button
              key={1}
              className={
                active === "thinktech"
                  ? "event-thinktech-active"
                  : "event-thinktech"
              }
              id={"thinktech"}
              onClick={handleThinktech}
            >
              ThinkTech
            </button>
          </li>
          <li className="event-list">
            <button
              key={2}
              className={
                active === "hackathon"
                  ? "event-thinktech-active"
                  : "event-thinktech"
              }
              id={"hackathon"}
              onClick={handleHackathon}
            >
              Hackathon
            </button>
          </li>
          <li className="event-list">
            <button
              key={3}
              className={
                active === "meetups"
                  ? "event-thinktech-active"
                  : "event-thinktech"
              }
              id={"meetups"}
              onClick={handleMeetups}
            >
              Meetups
            </button>
          </li>
        </ul>
        <div>
          {!isHackathonVisible && !isThinkTechVisible && !isMeetupsVisible && (
            <AllEvents />
          )}
          {isThinkTechVisible && <ThinkTechEvents />}
          {isHackathonVisible && <HackathonEvents />}
          {isMeetupsVisible && <Meetups />}
        </div>
      </div>
    </>
  );
};

export default Events;
