import Natale from "../../assets/img/ThinkTech6/Natale.jpg";
import Davide from "../../assets/img/ThinkTech6/Davide.jpg";
import Domenico from "../../assets/img/ThinkTech6/Domenico.jpg";
import Gianluigi from "../../assets/img/ThinkTech6/Gianluigi.jpg";
import "../../pages/ComingSoonEvent.css";

const detailsFirstSection = [
  {
    id: "1",
    active: false,
    name: "Natale Motta",
    title:
      "CEO Móveo Srl",
    img: Natale,
  },
  {
    id: "2",
    active: false,
    name: "Davide Filizola",
    title: `Chief Digital & Innovation Officer SISAL`,
    img: Davide,
  },
  {
    id: "3",
    active: false,
    name: "Domenico Talia",
    title: `Professor University of Calabria`,
    img: Domenico,
  },
  {
    id: "4",
    active: false,
    name: "Gianluigi Greco",
    title: `President of the Italian Association for Artificial Intelligence-Professor University of Calabria`,
    img: Gianluigi,
  }
];

export default detailsFirstSection;
