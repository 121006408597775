import React from "react";
import "../Footer/FooterSecond.css";
import MoveoLogo from "../../assets/img/MoveoLogo.svg";
import LandmarkLogo from "../../assets/img/LandmarkLogo.svg";
import { HashLink as Link } from "react-router-hash-link";
import Mailto from "reactv16-mailto";

function FooterSecond() {
  return (
    <>
      <div className="footer-container-second">
        <div className="footer-links-wrapper">
          <div className="footer-link-wrapper">
            <div className="footer-link-items-second">
              <Link to="/about">About the conference</Link>
              <Link to="/#events">Events</Link>
              <Link to="/#partners" data-after="Partners" smooth>
                Partners
              </Link>
            </div>
            <div className="footer-organizer-second">
              <Link to="/">Organizer</Link>
              <div className="logos">
                <img src={LandmarkLogo} className="landmark-logo" alt="logo" />
              <img src={MoveoLogo} className="moveo-logo" alt="logo"/> 
              </div>
             
              <p className="footer-subscription-heading">
                If you want to cooperate with us, become a partner or have
                another important issue, please contact us using the email
                below.
              </p>
              <Mailto
                className="footer-email"
                email="info@thinktech.digital"
                obfuscate={true}
                headers={{}}
              >
                info@thinktech.digital
              </Mailto>
            </div>
          </div>
          <div className="socialbtns-second" align="center">
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/thinktechalbania"
                  target="blank"
                >
                  <i className="fa fa-lg fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/landmark.al/" target="blank">
                  <i className="fa fa-lg fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/user/LandmarkAlbania"
                  target="blank"
                >
                  <i className="fa fa-lg fa-youtube"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-copyright-second">
          <small className="copyright-second">
            © Copyrights 2022 ThinkTech
          </small>
          {/* <small className="landmark">Landmark</small> */}
        </div>
      </div>
    </>
  );
}

export default FooterSecond;
