import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import ThinkTechSecondEdition from "./pages/Think2Edition";
import ThinkTechThirdEdition from "./pages/Think3Edition";
import ThinkTechFirstEdition from "./pages/Think1Edition";
import ThinkTechFourthEdition from "./pages/Think4Edition";
import HackathonFirstEdition from "./pages/Hackathon1Edition";
import HackathonSecondEdition from "./pages/Hackathon2Edition";
import ReactMeetUpFirstEdition from "./pages/React1MeetUp";
import ReactMeetUpSecondEdition from "./pages/React2MeetUp";
import ThinkTechFifthEdition from "./pages/Think5Edition";
import UXUIMeetUp from "./pages/UxUiMeetUp";
import PageNotFound from "./pages/PageNotFound";
import ComingSoonEvent from "./pages/ComingSoonEvent";
import ThinkTechSixthEdition from "./pages/Think6Edition";

function App() {
  return (
    <Layout>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route
          path="/thinktech-1st-edition"
          element={<ThinkTechFirstEdition />}
        />
        <Route
          path="/thinktech-2nd-edition"
          element={<ThinkTechSecondEdition />}
        />
        <Route
          path="/thinktech-3rd-edition"
          element={<ThinkTechThirdEdition />}
        />
        <Route
          path="/thinktech-4th-edition"
          element={<ThinkTechFourthEdition />}
        />
        <Route
          path="/thinktech-5th-edition"
          element={<ThinkTechFifthEdition />}
        />
        <Route
          path="/hackathon-1st-edition"
          element={<HackathonFirstEdition />}
        />
        <Route
          path="/hackathon-2nd-edition"
          element={<HackathonSecondEdition />}
        />
        <Route
          path="/react-meetup-1st-edition"
          element={<ReactMeetUpFirstEdition />}
        />
        <Route
          path="/react-meetup-2nd-edition"
          element={<ReactMeetUpSecondEdition />}
        />
        <Route path="/ux-ui-meetup" element={<UXUIMeetUp />} />
        <Route path="/thinktech-6th-edition" element={<ThinkTechSixthEdition/>}/>
        <Route path="/coming-soon-event" element={<ComingSoonEvent />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;
