import { useState, useEffect } from "react";
import "./PopUp.css";
import axios from "axios";

const API_URL = "http://localhost:3000/api/user/";

const PopUp = (props) => {
  const initialValues = { email: "" };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    console.log(value);
  };

  const submit = () => {};

  const subscribeHandler = async (e) => {
    e.preventDefault();
    const invalidValues = validate(formValues);
    setFormErrors(invalidValues);
    if (Object.keys(invalidValues).length === 0) {
      try {
      const response = await axios.post(
        API_URL + "subscribe",
        JSON.stringify({ formValues }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.data.error === "User exists") {
        setFormErrors(validate("User exists"));
      } else {
        setFormValues(initialValues);
        setFormErrors(validate(formValues));
        setIsSubmitting(true);
        handleClose();
      }
    } catch (err) {
      console.log(err);
    }
    }
    
  };

  const validate = (values) => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (values === "User exists") {
      errors.email = "User exists";
      return errors;
    }

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submit();
    }
  }, [formErrors]);
  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon-popup" onClick={props.handleClose}>
          x
        </span>
        <>
          <p className="subscribeText">
            Subscribe
            <p className="sub-text">
              to our newsletter and stay updated with new periodical events!
            </p>
          </p>
          <div className="form">
            <form className="email-form" onSubmit={subscribeHandler}>
              <input
                type="text"
                name="email"
                className="email__input"
                placeholder="example@hotmail.com"
                onChange={handleChange}
                required={true}
                value={formValues.email}
              />
              <div className="handling-error-email">
                {formErrors.email && (
                  <span className="error-email">{formErrors.email}</span>
                )}
              </div>
              <div>
                <button className="subscribe" onClick={() => handleClose()}>
                  <br />
                </button>
              </div>
            </form>
          </div>
        </>
      </div>
    </div>
  );
};

export default PopUp;
