import "./Editions.css";
import { useState, useEffect } from "react";
import Slider from "react-slick";

import ALB8735 from "../assets/img/ThinkTech4/ALB8735.jpg";
import ALB8766 from "../assets/img/ThinkTech4/ALB8766.jpg";
import ALB8793 from "../assets/img/ThinkTech4/ALB8793.jpg";
import DSC8125 from "../assets/img/ThinkTech4/DSC8125.jpg";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [ALB8735, ALB8766, ALB8793, DSC8125];

const ThinkTechFourthEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  return (
    <>
      <Header />
      <div className="homepage homepage-th4">
        <h2 className="title">ThinkTech 4th edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">“Tonin Harapi” Concert Hall</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">31st March 2022</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            It has become already a tradition to hold ThinkTech conference,
            which is focused entirely on technology and innovation. Our goal
            remains the same: inspiring and motivating young people to stay in
            Albania because our country offers a lot of opportunities in the
            field of technology.
            <br />
            <br />
            We invite you to participate on March 31st and get inspired by some
            of the most successful professionals in Albania who will share with
            us what drives them towards working in Albania. Web3 will be the
            main topic of this technological and digital conference.
            <br />
            <br />
            Don’t miss the great opportunity to create your network!
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech4edition" />
              <span
                className="close-icon close-icon-th4"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech4edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechFourthEdition;
