import React, { useState } from "react";
import FirstSection from "../components/EventSections/FirstSection";
import SecondSection from "../components/EventSections/SecondSection";
import FooterSecond from "../layouts/Footer/FooterSecond";
import Header from "../layouts/Header/Header";
import "./ComingSoonEvent.css";

const ComingSoonEvent = () => {
  const [isFirstSectionVisible, setIsFirstSectionVisible] = useState(true);
  const [isSecondSectionVisible, setIsSecondSectionVisible] = useState(false);
  const [active, setActive] = useState("");

  const handleFirstSectionPress = (e) => {
    setIsFirstSectionVisible((isVisible) => !isVisible);
    setIsSecondSectionVisible(false);
    setActive(e.target.id);
  };

  const handleSecondSectionPress = (e) => {
    setIsSecondSectionVisible((isVisible) => !isVisible);
    setIsFirstSectionVisible(false);
    setActive(e.target.id);
  };
  return (
    <>
      <Header />
      <div className="coming-soon-homepage">
        <h2 className="title">Thinktech 6th edition</h2>
        <div className="details-wrapper-coming-soon">
          <div className="location-icon">
            <p className="location-editions">Cosenza</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">27th Oct 2022</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            On February 5, 2020, was held for the first time in Albania, the
            conference of technological innovations, christened Think Tech.
            <br />
            <br />
            Always trying to bring our innovative ideas, this time we presented
            the conference that brought together the experiences of local
            companies and our partners from the most developed European
            countries, including the Netherlands, Italy, England, etc.
            <br />
            <br />
            The main goal of Think Tech is to unveil the many opportunities that
            our country offers, raising awareness of young people to contribute
            to our country, to make the change we need so much.
            <br />
            <br />
            Why Think Tech?
            <br />
            <br />
            Think Tech is not an ordinary conference, on the contrary, it is a
            unique event which focuses on youth development, partner
            initiatives, as well as training and professional advice in the
            local market.
            <br />
            <br />
            Presented by 10 lecturers, from the fields of technology and
            entrepreneurship, this conference originally intertwines the
            visions, success stories and the experiences in the fields of
            digital development and communication. This meeting also serves as a
            resource where you can be informed about the professional
            opportunities offered by Landmark Communications and Technologies
            and Móveo srl, as well as by our national and international
            partners.
          </p>
        </div>

        <div className="agenda-wrapper">
          <div className="event-agenda">
            <h2 className="event-agenda-title">Event Agenda</h2>
            <p className="event-agenda-details">
              15:00 Gathering of the Invitees
            </p>
            <p className="event-agenda-details">15:30 Start of the event</p>
          </div>

          <div className="first-section-event">
            <button
              key={1}
              className={
                active === "first-section"
                  ? "first-section-title-active"
                  : "first-section-title"
              }
              id={"first-section"}
              onClick={handleFirstSectionPress}
            >
              First Section
            </button>
            <p className="event-agenda-details-second">15:30 - 16:15</p>
            <p className="event-agenda-details-second">
              Safe Internet and Clean Network
            </p>
          </div>

          <div className="second-section-event">
            <button
              key={2}
              className={
                active === "second-section"
                  ? "second-section-title-active"
                  : "second-section-title"
              }
              id={"second-section"}
              onClick={handleSecondSectionPress}
            >
              Second Section
            </button>
            <p className="event-agenda-details-second">Coffee Break</p>
            <p className="event-agenda-details-second">
              16:15 - 16:45 / 16:45 - 17:30
            </p>
          </div>
        </div>
        <div className="sections">
          <h2 className="key-speakers-title">Key Speakers</h2>
          <span className="border"></span>

          {isFirstSectionVisible && <FirstSection />}
          {isSecondSectionVisible && <SecondSection />}
        </div>
      </div>

      <FooterSecond />
    </>
  );
};

export default ComingSoonEvent;
