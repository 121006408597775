import "../Header/Header.css";
import logo from "../../assets/img/logo.png";
import { useState, useEffect } from "react";
import PopUp from "../../components/PopUp/PopUp";
import { HashLink as Link } from "react-router-hash-link";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const toggleSubscribe = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = click ? 'hidden' : 'auto';
  }, [click])


 

  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = isOpen ? 'hidden' : 'auto';
  }, [isOpen])


  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <a href="/">
            <img
              src={logo}
              className="logo"
              alt="logo"
              onClick={closeMobileMenu}
            />
          </a>

          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <a
                href="/about"
                data-after="About"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                About
              </a>
            </li>
            <li className="nav-item">
              <Link
                to="/#partners"
                onClick={closeMobileMenu}
                data-after="Partners"
                className="nav-links"
                smooth
              >
                Partners
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/#events"
                onClick={closeMobileMenu}
                data-after="Events"
                className="nav-links"
                smooth
              >
                Events
              </Link>
            </li>
            <li className="nav-item">
              <button onClick={() => {toggleSubscribe(); closeMobileMenu();}}  className="nav-links-mobile">
                Stay updated
              </button>
            </li>
          </ul>

          {button && (
            <button onClick={toggleSubscribe} className="nav-link-desktop">
              Stay updated
            </button>
          )}
        </div>
        {isOpen && <PopUp handleClose={toggleSubscribe} />}
      </nav>
    </>
  );
};

export default Header;
