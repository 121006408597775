import "./Events.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";

const AllEvents = () => {
  return (
    <>
      <div>
      <Swiper
          watchSlidesProgress={true}
          slidesPerView={3}
          spaceBetween={11}
          
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 1st edition</p>
              <p className="thinktech-description">
                For the first time, Landmark Communications and Technologies
                introduced a conference which focuses entirely on technology as
                the field of the future.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">ArTurbina</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">5th Feb 2020</p>
              </div>
              <a href="/thinktech-1st-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 2nd edition</p>
              <p className="thinktech-description">
                On December 23rd 2021, was held for the second time in Albania,
                the conference of technological innovations, christened Think
                Tech.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Thinktech Livestream</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">23rd Dec 2020</p>
              </div>
              <a href="/thinktech-2nd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 3rd edition</p>
              <p className="thinktech-description">
                We invite you to participate on September 30th and get inspired by
                some of the most successful professionals in Albania who will
                share with us what drives them towards working in Albania...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                “Jordan Misja” Artistic Lyceum
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">30th Sep 2021</p>
              </div>
              <a href="/thinktech-3rd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 4th edition</p>
              <p className="thinktech-description">
                We invite you to participate on March 31st and get inspired by
                some of the most successful professionals in Albania who will
                share with us what drives them towards working in Albania...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                  “Tonin Harapi” Concert Hall
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">31st March 2022</p>
              </div>
              <a href="/thinktech-5th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 5th edition</p>
              <p className="thinktech-description">
                We were currently organizing the fifth edition of the Think Tech
                conference, but for the first time in Pristina, on June 16,
                2022, at Klan Arena... Don’t miss this great opportunity!
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Klan Arena, Prishtinë</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">16th Jun 2022</p>
              </div>
              <a href="/thinktech-5th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event">
            <div className="event-wrapper">
              <p className="events-title-card">ThinkTech 6th edition</p>
              <p className="thinktech-description">
              Welcome to the 6th edition of ThinkTech, powered by Móveo, with the
              patronage of the Municipality of Cosenza and the University of
              Calabria....
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">Castello Svevo Cosenza, Italy</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">27th Oct 2022</p>
              </div>
              <a href="/thinktech-6th-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="hackathon">
            <div className="event-wrapper">
              <p className="events-title-card">Hackathon 1st edition</p>
              <p className="thinktech-description">
                “Hackathon”, the technological event organized by Landmark for
                the first time in Albania. Interesting terms that have conquered
                the world of technology...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Mak Albania</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">23rd Dec 2020</p>
              </div>
              <a href="/hackathon-1st-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="hackathon">
            <div className="event-wrapper">
              <p className="events-title-card">Hackathon 2nd edition</p>
              <p className="thinktech-description">
                ThinkTech, this year will be a 2-day event, where the best
                professionals and the brightest university students come
                together to solve real problems through ...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                  “Jordan Misja” Artistic Lyceum
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">30th Mar 2022</p>
              </div>
              <a href="/hackathon-2nd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card">React Meetup 1st edition</p>
              <p className="thinktech-description">
                React Meetup was an event organized for all the developers and
                people who are passionate about technology. It had three main
                speakers.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">24th Jun 2021</p>
              </div>
              <a
                href="/react-meetup-1st-edition"
                className="event-informations"
              >
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card">React Meetup 2nd edition</p>
              <p className="thinktech-description">
                We are happy to welcome you to our second React Albania Meetup
                2021 event for all the developers and people who are passionate
                about technology.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">3rd Nov 2021</p>
              </div>
              <a
                href="/react-meetup-2nd-edition"
                className="event-informations"
              >
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="meetups">
            <div className="event-wrapper">
              <p className="events-title-card">UX/UI Meetup</p>
              <p className="thinktech-description">
                We were currently organizing the fifth edition of the Think Tech
                conference, but for the first time in Pristina, on June 16,
                2022, at Klan Arena. This edition’s topic was “Building bridges”
                between Kosovo and Albania, in all possible dimensions.
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Pool Tower</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">26th Aug 2021</p>
              </div>
              <a href="/ux-ui-meetup" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
        
      </div>
    </>
  );
};

export default AllEvents;
