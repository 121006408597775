import "./About.css";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const About = () => {
  return (
    <>
      <Header />
      <div className="about-page-title">
        <h4 className="about-title-t">ABOUT</h4>
        <h1 className="about-title-p">
          Think Tech conference which focuses completely on technology as the
          field of the future
        </h1>

        <br />
        <p className="about-paragraph-full">
          For the first time, Landmark Communications and Technologies
          introduced a conference which focuses entirely on technology as the
          field of the future. We have tried to bring an activity different from
          events or conferences that address the same topic, serving new areas
          and opportunities in the field of technology. Whether this will be
          innovative, attractive and useful is up to the public and the
          participants to decide.
          <br />
          <br />
          The “Think Tech” conference is focused on young people and the main
          goal is to convey a simple message, which I believe is very current.
          In summary, the call of “Think Tech” is: “Do not leave Albania,
          because even in the absence of initiatives of relevant institutions,
          our country still offers many opportunities in the field of
          technology.“
          <br />
          <br />
          The first relates to domestic and foreign enterprises. Domestic
          businesses, which play a key role as technology promoters, will offer
          their innovations in the digital field. This serves to acquaint young
          people with the many opportunities and different programs that these
          businesses or corporations have. Part of the conference are
          discussions on the opportunities that Albania offers, the reasons why
          our partners have chosen Albania for software and web development, as
          well as the positive and negative sides of distance management of a
          business, using the Outsource model. Another prism is what our job
          market offers and how much we compete with international corporations.
          This competition shows how capable we really are. The second part of
          the meeting focuses on studies, training, advice and assistance that
          international organizations that operate in our market, offer to
          Albanian youth. Due to the lack of information, both by young people
          and organizations, we thought to dedicate an important part to
          recognizing the programs, initiatives and innovations that foreign
          companies serve in the Albanian market. Primary for us is for young
          people to consider the opportunities that Albania offers. I am very
          much in favor of professional development abroad, as I gain an
          experience that serves you very well, based on my personal experience
          as a student and employee in one of the most developed countries in
          the world, namely in the Netherlands, but I stand by the view that
          looking for a new experience differs greatly from the mass exodus
          which is savagely attacking our country. Therefore, it is very
          important for me as an entrepreneur and for Albania to have talented
          young people who contribute in their country, in the field of the
          future.
          <br />
        </p>
        <br />
        <h4 className="ceo">Erion Isufi</h4>
        <p className="about-ceo">
          {" "}
          CEO & Founder, Landmark Communication and Technologies
        </p>
        <p className="about-ceo"> CEO Co-Founder Moveo Albania Technology</p>
      </div>
      <FooterMain />
    </>
  );
};

export default About;
