import "../../pages/ComingSoonEvent.css";
import Collapsible from "react-collapsible";
import detailsSecondSection from "./DetailsSecondSection";
import "../../pages/ComingSoonEvent.css";
import { useState } from "react";

const SecondSection = () => {
  const [detail, setDetails] = useState(detailsSecondSection);
  return (
    <>
      <div className="key-speakers-first">
        <section className="accordion">
          <div className="accordion-row">
            {detail.map((detail) => {
              return (
                <div className="left-content">
                  <Collapsible
                    className="Collapsible"
                    trigger={[
                      <i class="fa fa-2x fa-solid fa-angle-down"></i>,
                      <p className="event-content">
                        {" "}
                        <span className="panel-speakers">
                          {detail.name}
                        </span>{" "}
                        <br/>
                        {detail.title}
                      </p>,
                    ]}
                    triggerWhenOpen={[
                      <i class="fa fa-2x  fa-solid fa-angle-up"></i>,
                    ]}
                  >
                    <div className="content-show">
                      <p className="panel-title">
                        {" "}
                        <span className="panel-speakers">
                          {detail.name}
                        </span>{" "}
                        <br/>
                        {detail.title}
                      </p>
                      <img className="panel-img" src={detail.img}></img>
                    </div>
                  </Collapsible>
                </div>
              );
            })}
          </div>
        </section>
        {/* <div className="closing-event">
          <p className="event-closing-details">
            17:30 - 19:00 / Closing the event
          </p>
          <p className="event-closing-activities">Cocktail and music</p>
        </div> */}
      </div>
    </>
  );
};

export default SecondSection;
