import "./Editions.css";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import hackathon2E1 from "../assets/img/Hackathon2/hackathon2E1.jpg";
import hackathon2E2 from "../assets/img/Hackathon2/hackathon2E2.jpg";
import hackathon2E3 from "../assets/img/Hackathon2/hackathon2E3.jpg";
import hackathon2E4 from "../assets/img/Hackathon2/hackathon2E3.jpg";
import hackathon2E5 from "../assets/img/Hackathon2/hackathon2E4.jpg";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [
  hackathon2E1,
  hackathon2E2,
  hackathon2E3,
  hackathon2E4,
  hackathon2E5,
];

const HackathonSecondEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = model ? 'hidden' : 'auto';
  }, [model])


  return (
    <>
      <Header />
      <div className="homepage homepage-h2">
        <h2 className="title">Hackathon 2nd edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">
              “Tonin Harapi” Concert Hall, “Jordan Misja” Artistic Lyceum,
              Tirana
            </p>
          </div>
          <div className="date-icon">
            <p className="date-editions">30th March 2022</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            ThinkTech, this year will be a 2-day event, where the best
            professionals and the brightest university students come together to
            solve real problems through Smart Solutions, learn and create
            networking.
            <br />
            <br />
            Smart Solutions Hackathon will be held on March 30th where the
            smartest project will be judged based on creativity, usefulness,
            modularity, solution, and presentation.
            <br />
            <br />7 teams with some really interesting themes will participate
            and be with us during the live streaming. May the smartest solution
            win!
          </p>
          <div className={model ? "model open" : "model"}>
          <div className="img-container">
            <img src={tempimgSrc} alt="hackathon2edition" />
            <span className="close-icon close-icon-h2" onClick={() => setModel(false)}>
              x
            </span>
          </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className="slide"
                onClick={() => getImg(img)}
              >
                <img src={img} alt="hackathon2edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default HackathonSecondEdition;
