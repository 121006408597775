import "./Events.css";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";

const HackathonEvents = () => {
  return (
    <>
      <div>
      <Swiper
          watchSlidesProgress={true}
          // slidesPerView={3}
          slidesPerView={3}
          spaceBetween={11}
          pagination={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          <SwiperSlide className="thinktech-event" id="hackathon">
            <div className="event-wrapper">
              <p className="events-title-card">Hackathon 1st edition</p>
              <p className="thinktech-description">
                “Hackathon”, the technological event organized by Landmark for
                the first time in Albania. Interesting terms that have conquered
                the world of technology...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions"> Mak Albania</p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">23rd Dec 2020</p>
              </div>
              <a href="/hackathon-1st-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>

          <SwiperSlide className="thinktech-event" id="hackathon">
            <div className="event-wrapper">
              <p className="events-title-card">Hackathon 2nd edition</p>
              <p className="thinktech-description">
                ThinkTech, this year will be a 2-day event, where the best
                professionals and the brightest university students come
                together to solve real problems through ...
              </p>
              <div className="event-location-icon">
                <p className="event-location-editions">
                  “Jordan Misja” Artistic Lyceum
                </p>
              </div>
              <div className="event-date-icon">
                <p className="event-date-editions">30th Mar 2022</p>
              </div>
              <a href="/hackathon-2nd-edition" className="event-informations">
                <p className="event-more-informations">
                  see more about this edition
                </p>
              </a>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );
};

export default HackathonEvents;
