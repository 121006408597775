import { useState , useEffect} from "react";
import Slider from "react-slick";
import "./Editions.css";
import react2E1 from "../assets/img/ReactMeetup2/react2E1.png";
import react2E2 from "../assets/img/ReactMeetup2/react2E2.png";
import react2E3 from "../assets/img/ReactMeetup2/react2E3.png";
import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [react2E1, react2E2, react2E3];

const ReactMeetUpSecondEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = model ? 'hidden' : 'auto';
  }, [model])

  return (
    <>
      <Header />
      <div className="homepage homepage-r2">
        <h2 className="title">React 2nd edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">
              Dritan Hoxha Str. Pool Tower, 9th floor building
            </p>
          </div>
          <div className="date-icon">
            <p className="date-editions">3rd November 2021</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            We are happy to welcome you to our second React Alania Meetup 2021
            event for all the developers and people who are passionate about
            technology.
            <br />
            <br />
            The event will take place on Wednesday (November, 3) at 5:30 PM,
            both online and at our premises.
            <br />
            <br />
            You might come and visit us at our offices at Dritan Hoxha Str. Pool
            Tower, Tirana or watch the event Live on our YouTube Channel or join
            our zoom call to be part of it.
          </p>
          <div className={model ? "model open" : "model"}>
          <div className="img-container">
            <img src={tempimgSrc} alt="react2meetup" />
            <span className="close-icon close-icon-r2" onClick={() => setModel(false)}>
              x
            </span>
          </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className= "slide"
                onClick={() => getImg(img)}
              >
                <img src={img} alt="react2meetup" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ReactMeetUpSecondEdition;
