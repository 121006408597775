import Erion from "../../assets/img/ThinkTech6/Erion.jpg";
import Antonello from "../../assets/img/ThinkTech6/Antonello.jpg";
import Sebastiano from "../../assets/img/ThinkTech6/Sebastiano.jpg";
import "../../pages/ComingSoonEvent.css";

const detailsSecondSection = [
  {
    id: "1",
    active: false,
    name: "Erion Isufi",
    title:
      "CEO Landmark and Founder of Think Tech",
    img: Erion,
  },
  {
    id: "2",
    active: false,
    name: "Antonello Gagliard",
    title: `Strategic Alliances & Channel Sale S3K`,
    img: Antonello,
  },
  ,
  {
    id: "3",
    active: false,
    name: "Sebastiano Moccia",
    title: `Director GSS & Chief Consultant Officer S3K`,
    img: Sebastiano,
  }
];

export default detailsSecondSection;
