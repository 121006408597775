import { useState , useEffect} from "react";
import "./Editions.css";
import FooterMain from "../layouts/Footer/FooterMain";
import Slider from "react-slick";
import uxui1 from "../assets/img/UxUiMeetup/uxui1.png";
import uxui2 from "../assets/img/UxUiMeetup/uxui2.png";
import uxui3 from "../assets/img/UxUiMeetup/uxui3.png";
import uxui4 from "../assets/img/UxUiMeetup/uxui4.png";
import uxui5 from "../assets/img/UxUiMeetup/uxui5.png";
import Header from "../layouts/Header/Header";

const images = [uxui1, uxui2, uxui3, uxui4, uxui5];

const UXUIMeetUp = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "60px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  
  useEffect(() => {
    const body = document.querySelector('body');
    body.style.overflow = model ? 'hidden' : 'auto';
  }, [model])

  return (
    <>
      <Header />
      <div className="homepage homepage-uxui">
        <h2 className="title">UX/UI Meetup</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">Pool Albania</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">26th Aug 2021</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p>
            We were currently organizing the fifth edition of the Think Tech
            conference, but for the first time in Pristina, on June 16, 2022, at
            Klan Arena. This edition’s topic was “Building bridges” between
            Kosovo and Albania, in all possible dimensions.
            <br />
            <br />
            We invited you to participate on June 16th and get inspired by some
            of the most successful professionals in Kosovo and Albania who
            shared with us what drives them toward working in these countries.
            <br />
            <br />
            Don’t miss the great opportunity to create your network!
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
            <img src={tempimgSrc} alt="uxuimeetup" />
            <span className=".close-icon-uxuiicon close-icon-uxui" onClick={() => setModel(false)}>
              x
            </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div
                className= "slide"
                onClick={() => getImg(img)}
              >
                <img src={img} alt="uxuimeetup" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default UXUIMeetUp;
