import { useState, useEffect } from "react";
import "./Editions.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import DSC1362 from "../assets/img/ThinkTech1/DSC1362.JPG";
import DSC1492 from "../assets/img/ThinkTech1/DSC1492.JPG";
import DSC1723 from "../assets/img/ThinkTech1/DSC1723.JPG";
import DSC1744 from "../assets/img/ThinkTech1/DSC1744.jpg";
import DSC1771 from "../assets/img/ThinkTech1/DSC1771.JPG";
import DSC1896 from "../assets/img/ThinkTech1/DSC1896.JPG";

import Header from "../layouts/Header/Header";
import FooterMain from "../layouts/Footer/FooterMain";

const images = [DSC1362, DSC1492, DSC1723, DSC1744, DSC1771, DSC1896];

const ThinkTechFirstEdition = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");

  const getImg = (img) => {
    setTempImgSrc(img);
    setModel(true);
  };

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = model ? "hidden" : "auto";
  }, [model]);

  const settings = {
    // className: "center",
    // centerMode: true,
    infinite: true,
    // centerPadding: "10px",
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    arrows: false,
    beforeChange: (current, next) => setImageIndex(next),
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          speed: 3500,
          infinite: true,
          autoplaySpeed: 2000,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Header />
      <div className="homepage homepage-th1">
        <h2 className="title">ThinkTech 1st edition</h2>
        <div className="details-wrapper">
          <div className="location-icon">
            <p className="location-editions">ArTurbina</p>
          </div>
          <div className="date-icon">
            <p className="date-editions">5th Feb 2020</p>
          </div>
        </div>

        <div className="thinktech-details">
          <p className="paragraph">
            On February 5, 2020, was held for the first time in Albania, the
            conference of technological innovations, christened Think Tech.
            <br />
            <br />
            Always trying to bring our innovative ideas, this time we presented
            the conference that brought together the experiences of local
            companies and our partners from the most developed European
            countries, including the Netherlands, Italy, England, etc.
            <br />
            <br />
            The main goal of Think Tech is to unveil the many opportunities that
            our country offers, raising awareness of young people to contribute
            to our country, to make the change we need so much.
            <br />
            <br />
            Why Think Tech?
            <br />
            <br />
            Think Tech is not an ordinary conference, on the contrary, it is a
            unique event which focuses on youth development, partner
            initiatives, as well as training and professional advice in the
            local market.
            <br />
            <br />
            Presented by 10 lecturers, from the fields of technology and
            entrepreneurship, this conference originally intertwines the
            visions, success stories and the experiences in the fields of
            digital development and communication. This meeting also serves as a
            resource where you can be informed about the professional
            opportunities offered by Landmark Communications and Technologies
            and Móveo srl, as well as by our national and international
            partners.
          </p>
          <div className={model ? "model open" : "model"}>
            <div className="img-container">
              <img src={tempimgSrc} alt="thinktech1edition" />
              <span
                className="close-icon close-icon-th1"
                onClick={() => setModel(false)}
              >
                x
              </span>
            </div>
          </div>
          <Slider {...settings}>
            {images.map((img, idx) => (
              <div className="slide" onClick={() => getImg(img)}>
                <img src={img} alt="thinktech1edition" />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <FooterMain />
    </>
  );
};

export default ThinkTechFirstEdition;
